import React from "react";

import { DefaultLayout } from "../components/layouts";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <DefaultLayout>
    <SEO title="Not found" />
    <div className="near-white tc f3 pt4-ns">
      <h1 className="c-f-sans">Not Found</h1>
      <p>This page could not be found.</p>
    </div>
  </DefaultLayout>
);

export default NotFoundPage;
